import { Player, BigPlayButton, ControlBar, VolumeMenuButton, LoadingSpinner, CurrentTimeDisplay, FullscreenToggle, PlayToggle, ReplayControl } from "video-react"
import "../../../node_modules/video-react/dist/video-react.css"
import './video-react-custom.css'
import { Box, Flex, Paper, Text } from "@mantine/core";
import { useEffect, useRef } from "react";

interface VideoPlayerProps {
    videoURL: string | undefined | null;
    height?: number;
}

export const VideoPlayer: React.FC<VideoPlayerProps> = ({ videoURL, height = 400 }) => {

    const playerRef = useRef(null);
    useEffect(() => {
        const player = playerRef.current
        if (player) {
          // @ts-ignore  
          player.load();
        }
    }, [videoURL]);

    return (
        <Box h={height} w={height * 0.5625} mx={5}>
            {videoURL &&
            <Player
                ref={playerRef}
                fluid={false}
                width={height * 0.5625}
                height={height}
                preload="auto"
                playsInline={true}
                //muted={true}
                //loop={true}
                poster={videoURL?.replace(".mp4", ".png").replace("/video/upload/", "/video/upload/so_0/c_fill/h_400/w_225/")}
                // @ts-ignore
                className="player">
                <source src={videoURL?.replace("/video/upload/", "/video/upload/c_fill/h_400/w_225/")} />
                <BigPlayButton position="center" />
                <LoadingSpinner />
                <ControlBar autoHide={false} disableDefaultControls >
                    <PlayToggle />
                    <VolumeMenuButton vertical />
                    <CurrentTimeDisplay />
                    <ReplayControl seconds={30} />
                    {/* 
                    // @ts-ignore */}
                    <FullscreenToggle />
                </ControlBar>
            </Player>
            }
            {!videoURL && <Paper h={height} w={height * 0.5625} radius="lg"><Flex h={height} w={height * 0.5625} align="center" justify="center"><Text>No video</Text></Flex></Paper>}
        </Box>
    )
}