import { HttpError, IResourceComponentsProps, useList, useNotification, useUpdate } from "@refinedev/core"
import { ActionIcon, Box, Button, Flex, Stack, Text, Title } from "@mantine/core"
import { ITalent } from "interfaces";
import { MRT_ColumnDef, MantineReactTable } from "mantine-react-table";
import { useEffect, useMemo, useState } from "react";
import { IconCheck, IconRefresh } from "@tabler/icons";
import { TalentDetailPanel } from "components/talent/talentDetailPanel";
import { EditButton } from "@refinedev/mantine";
import { NETLIFY_BUILD_HOOK } from "../../constants";

export const Dashboard: React.FC<IResourceComponentsProps> = () => {
    const { data: enrollTalentsData, isLoading, isError, refetch: refetchEnroll } = useList<ITalent, HttpError>({
        resource: "talents",
        metaData: {
            populate: {
                users_permissions_user: {
                    populate: {
                        email: true
                    },
                }
            }
        },
        filters: [
            {
                field: "enrollAccepted",
                operator: "eq",
                value: "false",
            },
        ],
    });
    const enrollTalents = enrollTalentsData?.data ?? [];
    const enrollColumns = useMemo<MRT_ColumnDef<ITalent>[]>(
        () => [
            {
                accessorKey: 'name',
                header: 'Talent',
                id: 'name',
                enableColumnFilterModes: false,
                Cell: ({ renderedCellValue, row }) => (
                    <Box
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                            gap: '16px',
                        }}
                    >
                        <Text size="lg">{renderedCellValue}</Text>
                        <Text>{row.original.socialPlatform}: {row.original.socialLinks} </Text>
                        <Text>Mobile: {row.original.mobileNumber}</Text>
                        <Text>Email: {row.original.users_permissions_user?.email}</Text>
                    </Box>
                ),
            },
            {
                accessorFn: (row) => {
                    //const sDay = new Date(row.createdAt);
                    //sDay.setHours(0, 0, 0, 0); // remove time from date (useful if filter by equals exact date)
                    return new Date(row.createdAt);
                },
                //filterVariant: 'date-range',
                sortingFn: 'datetime',
                id: 'createdAt',
                header: 'Registration',
                enableColumnFilterModes: false,
                enableColumnFilter: false,
                Cell: ({ cell }) => cell.getValue<Date>()?.toLocaleDateString(),
            },
        ], []
    )
    const { mutate: acceptEnrollment } = useUpdate<ITalent>();
    const handleAcceptEnrollment = async (talentId: number) => {
        acceptEnrollment(
            {
                resource: "talents",
                values: {
                    enrollAccepted: true
                },
                id: talentId,
                mutationMode: "pessimistic",
                successNotification: () => {
                    return {
                        message: `Enroll accepted`,
                        type: "success",
                    };
                },
                errorNotification: () => {
                    return {
                        message: `Enroll accept failed`,
                        type: "error",
                    };
                },
            },
            {
                onSuccess: () => {
                    refetchEnroll();
                },
            },
        );
    };

    const { data: activateTalentsData, isLoading: isLoadingActivate, isError: isErrorActivate, refetch: refetchActivate } = useList<ITalent, HttpError>({
        resource: "talents",
        filters: [
            {
                field: "activateRequestAccepted",
                operator: "eq",
                value: "false",
            },
        ],
        metaData: {
            populate: {
                subcategory: {
                    populate: '*',
                    category: {
                        populate: '*',
                    },
                },
                image: {
                    populate: '*',
                },
                tags: {
                    populate: '*',
                },
                users_permissions_user: {
                    populate: {
                        email: true
                    },
                }
            }
        },
    });
    const [activateTalents, setActivateTalents] = useState<ITalent[]>([]);
    useEffect(() => {
        if (activateTalentsData?.data) {
            setActivateTalents(activateTalentsData.data ?? [])
        }
    }, [activateTalentsData?.data])

    const activateColumns = useMemo<MRT_ColumnDef<ITalent>[]>(
        () => [
            {
                accessorKey: 'name',
                header: 'Talent',
                id: 'name',
                enableColumnFilterModes: false,
                Cell: ({ renderedCellValue, row }) => (
                    <Text>{renderedCellValue}</Text>
                ),
            },
            {
                accessorFn: (row) => {
                    //const sDay = new Date(row.createdAt);
                    //sDay.setHours(0, 0, 0, 0); // remove time from date (useful if filter by equals exact date)
                    return new Date(row.createdAt);
                },
                //filterVariant: 'date-range',
                sortingFn: 'datetime',
                id: 'createdAt',
                header: 'Registration',
                enableColumnFilterModes: false,
                enableColumnFilter: false,
                Cell: ({ cell }) => cell.getValue<Date>()?.toLocaleDateString(),
            },
        ], []
    )
    const { open: openNotification } = useNotification();
    const [deploying, setDeploying] = useState(false);
    const triggerDeploy = () => {
        setDeploying(true);
        fetch(`${NETLIFY_BUILD_HOOK}?trigger_title=admin+dashboard+trigger&clear_cache=true`, {
            method: 'POST'
        })
            .then(response => {
                if (response.ok) {
                    openNotification?.({
                        type: "success",
                        message: "Frontend deploy triggered. It will take several minutes for the changes to show up.",
                    });
                } else {
                    openNotification?.({
                        type: "error",
                        message: `Failed to trigger frontend deploy. ${NETLIFY_BUILD_HOOK}?trigger_title=admin+dashboard+trigger&clear_cache=true`,
                    });
                }
                setDeploying(false);
            })
            .catch(error => {
                openNotification?.({
                    type: "error",
                    message: "An error occurred while triggering deployment.",
                });
                setDeploying(false);
            });
    };

    const handleRefreshEnroll = async () => {
        try { await refetchEnroll() } catch (error) {
            console.error("Error occurred while refreshing enroll data:", error)
        }
    }

    const handleRefreshActivate = async () => {
        try { await refetchActivate() } catch (error) {
            console.error("Error occurred while refreshing activate data:", error)
        }
    }

    return (
        <Stack>
            <MantineReactTable
                columns={enrollColumns}
                data={enrollTalents ?? []} //fallback to array if data is undefined
                state={{ isLoading: isLoading }}
                mantineToolbarAlertBannerProps={isError ? { color: 'red', children: 'Error loading data', } : undefined}
                renderTopToolbarCustomActions={() => {
                    return (
                        <Flex align="baseline" gap="md">
                            <Title order={3} >Enroll requests</Title>
                            <Text size="sm">{enrollTalents?.length} entries found</Text>
                            <ActionIcon variant="transparent" onClick={handleRefreshEnroll}><IconRefresh size={18} /></ActionIcon>
                        </Flex>
                    )
                }}
                initialState={{
                    sorting: [{ id: 'createdAt', desc: true, },],
                }}
                enableRowActions
                displayColumnDefOptions={{
                    'mrt-row-actions': {
                        header: '', //change header text
                        size: 100, //make actions column wider
                    },
                }}
                renderRowActions={({ row }) => (
                    <Box w={100}>
                        <Button leftIcon={<IconCheck />} color="green" onClick={() => handleAcceptEnrollment(row.original.id)}>
                            Accept
                        </Button>
                    </Box>
                )}
            />

            <MantineReactTable
                columns={activateColumns}
                data={activateTalents ?? []} //fallback to array if data is undefined
                state={{ isLoading: isLoadingActivate }}
                mantineToolbarAlertBannerProps={isErrorActivate ? { color: 'red', children: 'Error loading data', } : undefined}
                renderTopToolbarCustomActions={() => {
                    return (
                        <Flex align="baseline" gap="md">
                            <Title order={3}>Activation requests</Title>
                            <Text size="sm">{activateTalents?.length} entries found</Text>
                            <ActionIcon variant="transparent" onClick={handleRefreshActivate}><IconRefresh size={18} /></ActionIcon>
                            <Button variant="light" compact size="xs" onClick={triggerDeploy} disabled={deploying}>
                                {deploying ? 'Deploying...' : 'Deploy Frontend'}
                            </Button>
                        </Flex>
                    )
                }}
                initialState={{
                    sorting: [{ id: 'createdAt', desc: true, },],
                    expanded: true,
                }}
                enableRowActions
                renderRowActions={({ row }) => (
                    <EditButton
                        resource="talents"
                        recordItemId={row.original.id}
                    />
                )}
                renderDetailPanel={({ row }) => (
                    <TalentDetailPanel talent={row.original} />
                )}
            />
        </Stack >
    )
}
