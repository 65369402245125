import { ActionIcon, Avatar, Box, Flex, Stack, Title, Text } from "@mantine/core";
import { CrudFilters, CrudSorting, HttpError, IResourceComponentsProps, useList, useTable } from "@refinedev/core";
import { ISubcategory, ITalent } from "interfaces";
import { type MRT_ColumnDef, MantineReactTable, MRT_SortingState, MRT_ColumnFiltersState } from "mantine-react-table";
import { useEffect, useMemo, useState } from "react";
import { TalentDetailPanel } from "components/talent/talentDetailPanel";
import { EditButton } from "@refinedev/mantine";
import { IconRefresh } from "@tabler/icons";

export const TalentList: React.FC<IResourceComponentsProps> = () => {
    const { tableQueryResult, setCurrent, setPageSize, pageCount, setFilters, setSorters } = useTable<ITalent, HttpError>({
        resource: "talents",
        metaData: {
            populate: {
                subcategory: {
                    populate: '*',
                    category: {
                        populate: '*',
                    },
                },
                image: {
                    populate: '*',
                },
                tags: {
                    populate: '*',
                },
                users_permissions_user: {
                    populate: {
                        email: true
                    },
                }
            }
        },
        filters: {
            permanent: [{
                field: "enrollAccepted",
                operator: "eq",
                value: true,
            }],
            defaultBehavior: "replace",
        },
    });
    const talents = tableQueryResult?.data?.data ?? [];
    //console.log(talents)

    const { data: subCategoriesData } = useList<ISubcategory, HttpError>({
        resource: "subcategories",
    });
    const subcategoryNames = useMemo(() => subCategoriesData?.data.map(item => item.name) ?? [], [subCategoriesData?.data])

    const columns = useMemo<MRT_ColumnDef<ITalent>[]>(
        () => [
            {
                accessorKey: 'name',
                header: 'Name',
                id: 'name',
                enableColumnFilterModes: false,
                Cell: ({ renderedCellValue, row }) => (
                    <Box
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                            gap: '16px',
                        }}
                    >
                        {row.original.image &&
                            <Avatar
                                src={row.original.image[0].url}
                                size={24}
                                radius="lg"
                            />
                        }
                        <span>{renderedCellValue}</span>
                    </Box>
                ),
                columnFilterModeOptions: ['contains'],
            },
            {
                accessorKey: 'subcategory.name',
                header: 'Subcat.',
                enableColumnFilterModes: false,
                mantineFilterSelectProps: {
                    data: subcategoryNames as any,
                },
                filterVariant: 'select',
                size: 80,
            },
            {
                accessorKey: 'price',
                columnFilterModeOptions: ['between', 'greaterThan', 'lessThan'], //only allow these filter modes
                filterFn: 'greaterThan',
                header: '€',
                size: 70,
                enableColumnFilterModes: false,
                enableColumnFilter: false,
            },
            {
                accessorFn: (row) => {
                    //const sDay = new Date(row.createdAt);
                    //sDay.setHours(0, 0, 0, 0); // remove time from date (useful if filter by equals exact date)
                    return new Date(row.createdAt);
                },
                //filterVariant: 'date-range',
                sortingFn: 'datetime',
                id: 'createdAt',
                header: 'Reg.',
                enableColumnFilterModes: false,
                enableColumnFilter: false,
                Cell: ({ cell }) => cell.getValue<Date>()?.toLocaleDateString(),
                size: 70,
            },
            {
                accessorKey: 'viewCount',
                header: 'Views',
                size: 70,
                enableColumnFilterModes: false,
                enableColumnFilter: false,
            },
            {
                accessorKey: 'completeOrderCount',
                header: 'ComplOrd',
                size: 30,
                enableColumnFilterModes: false,
                enableColumnFilter: false,
            },
            {
                accessorFn: (originalRow) => (originalRow.active ? 'true' : 'false'),
                id: 'active',
                header: 'Active',
                filterVariant: 'select',
                mantineFilterSelectProps: {
                    data: ['Active', 'Inactive'] as any
                },
                enableColumnFilterModes: false,
                Cell: ({ cell }) => cell.getValue() === 'true' ? 'Active' : 'Inactive',
                size: 80,
            },
            {
                accessorFn: (originalRow) => (originalRow.activateRequestAccepted === null ? 'Pending' : (originalRow.activateRequestAccepted === true ? 'Accepted' : 'Requested')),
                id: 'activateRequestAccepted',
                header: 'Activation',
                enableColumnFilterModes: false,
                filterVariant: 'select',
                mantineFilterSelectProps: {
                    data: ['Pending', 'Accepted', 'Requested'] as any
                },
                size: 80,
                //Cell: ({ cell }) => cell.getValue() === 'waiting' ? 'Waiting' : cell.getValue() === 'true' ? 'Active' : 'Inactive',
            },

        ],
        [subcategoryNames],
    )
    const [pagination, setPagination] = useState({
        pageIndex: 0,
        pageSize: 10,
    });
    useEffect(() => {
        setCurrent(pagination.pageIndex + 1);
        setPageSize(pagination.pageSize);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pagination.pageIndex, pagination.pageSize,]);

    const [sorting, setSorting] = useState<MRT_SortingState>([]);

    useEffect(() => {
        setSorters([{ field: "createdAt", order: "desc" }])
        setSorting([{ id: "createdAt", desc: true }])
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        const crudSorting: CrudSorting = sorting.map((sorter) => ({
            field: sorter.id,
            order: sorter.desc ? "desc" : "asc",
        }));
        setSorters(crudSorting)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [sorting])

    const [columnFilters, setColumnFilters] = useState<MRT_ColumnFiltersState>([]);
    useEffect(() => {
        const crudFilters: CrudFilters = columnFilters.map((filter) => ({
            field: filter.id,
            value: filter.id === 'active' ? (filter.value === 'Active' ? true : false) : (filter.id === 'activateRequestAccepted' ? (filter.value === 'Pending' ? "null" : (filter.value === 'Accepted' ? true : false)) : filter.value),
            operator: filter.id === 'name' ? 'contains' : (filter.id === 'activateRequestAccepted' && filter.value === 'Pending') ? 'null' : 'eq',
            condition: 'and',
        }));
        setFilters(crudFilters);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [columnFilters]);

    const handleRefresh = async () => {
        try {
            await tableQueryResult.refetch();
        } catch (error) {
            console.error("Error occurred while refreshing table data:", error);
        }
    };

    //const { edit } = useNavigation();
    return (
        <Stack>
            <MantineReactTable
                columns={columns}
                data={talents ?? []} //fallback to array if data is undefined
                state={{ isLoading: tableQueryResult?.isLoading, pagination: pagination, sorting: sorting }}
                //enableStickyHeader
                enableRowActions
                displayColumnDefOptions={{
                    'mrt-row-actions': {
                        header: '', //change header text
                        size: 60, //make actions column wider
                    },
                }}
                manualSorting
                onSortingChange={setSorting}
                manualFiltering
                enableGlobalFilter={false}
                onColumnFiltersChange={setColumnFilters}
                initialState={{
                    showColumnFilters: true,
                }}
                mantineToolbarAlertBannerProps={tableQueryResult?.isError ? { color: 'red', children: 'Error loading data', } : undefined}
                manualPagination={true}
                pageCount={pageCount}
                onPaginationChange={setPagination}
                rowCount={tableQueryResult.data?.total}
                mantinePaginationProps={{
                    rowsPerPageOptions: ['10', '20', '50', '100']
                }}
                /*renderRowActionMenuItems={({ row }) => [
                    <Menu.Item key={`${row.original.slug}-edit`} icon={<IconEdit/>} onClick={() => edit("talents",row.original.id)}>Edit Talent</Menu.Item>,
                    <Menu.Item key={`${row.original.slug}-view`} icon={<IconUserCircle />} onClick={() => window.open(`${FRONTEND_URL}/${row.original.slug}`, '_blank')}>View on BVTV</Menu.Item>,
                ]}*/
                renderTopToolbarCustomActions={() => {
                    return (
                        <Flex align="baseline" gap="md">
                            <Title order={3} >Talents</Title>
                            <Text size="sm">{tableQueryResult.data?.total} entries found</Text>
                            <ActionIcon variant="transparent" onClick={handleRefresh}><IconRefresh size={18} /></ActionIcon>
                        </Flex>
                    )
                }}
                renderRowActions={({ row }) => (
                    <EditButton
                        resource="talents"
                        recordItemId={row.original.id}
                    />
                )}
                renderDetailPanel={({ row }) => (
                    <TalentDetailPanel talent={row.original} />
                )}
            />
        </Stack>
    );
};
