import { Box, Avatar, Stack, Group, Title, Badge, Text, Button } from "@mantine/core";
import { IconVideo, IconVideoPlus } from "@tabler/icons";
import { FRONTEND_URL } from "../../constants";
import { ITag, ITalent } from "interfaces";

interface TalentDetailPanelProps {
    talent: ITalent
}

export const TalentDetailPanel: React.FC<TalentDetailPanelProps> = ({ talent }) => {
    return (
        <Box
            sx={{
                display: 'flex',
                justifyContent: 'flex-start',
                alignItems: 'center',
                gap: '16px',
                padding: '16px',
            }}
        >
            {talent.image &&
                <Avatar
                    src={talent.image[0].url}
                    size={100}
                    radius="lg"
                />
            }
            <Stack>
                <Group>
                    <Title order={3}>{talent.name}</Title>
                    <Text>{talent.socialPlatform} : {talent.socialLinks}</Text>
                    <Text>{talent.mobileNumber}</Text>
                    <Text>{talent.users_permissions_user?.email}</Text>
                    {talent.tags &&
                        <Group>
                            {talent.tags.map((tag: ITag) => (
                                <Badge key={tag.slug}>
                                    {tag.name}
                                </Badge>
                            ))}
                        </Group>
                    }
                    <Button size="sm" variant="outline" compact disabled={talent.subcategory == null} onClick={() => window.open(`${FRONTEND_URL}/${talent.slug}`, '_blank')}>Show on BV TV</Button>
                </Group>
                <Text>{talent.description}</Text>
                <Group spacing={0}>
                    <Group spacing={5} pr={30}>
                        <IconVideo />
                        <Text weight={400} size="sm" >Delivery: within {talent.deliveryDays ?? 7} days</Text>
                    </Group>
                    {talent.fastDelivery &&
                        <Group spacing={5}>
                            <IconVideoPlus />
                            <Text weight={400} size="sm" >Fast delivery: within {talent.fastDeliveryDays ?? 1} day{talent?.fastDeliveryDays > 1 ? "s" : ""}</Text>
                        </Group>
                    }
                </Group>
            </Stack>
        </Box>
    )
}