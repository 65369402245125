import { Paper, Button, Stack, Text, Title, Group } from "@mantine/core"
import { IResourceComponentsProps, useNotification } from "@refinedev/core"
import { useState } from "react";
import { NETLIFY_BUILD_HOOK } from "../../constants";

export const Deploy: React.FC<IResourceComponentsProps> = () => {
    const { open: openNotification } = useNotification();
    
    const [deploying, setDeploying] = useState(false);
    const triggerDeploy = () => {
        setDeploying(true);
        fetch(`${NETLIFY_BUILD_HOOK}?trigger_title=admin+manual+trigger&clear_cache=true`, {
            method: 'POST'
        })
            .then(response => {
                if (response.ok) {
                    openNotification?.({
                        type: "success",
                        message: "Frontend deploy triggered. It will take several minutes for the changes to show up.",
                    });
                } else {
                    openNotification?.({
                        type: "error",
                        message: `Failed to trigger frontend deploy ${NETLIFY_BUILD_HOOK}?trigger_title=admin+manual+trigger&clear_cache=true`,
                    });
                }
                setDeploying(false);
            })
            .catch(error => {
                openNotification?.({
                    type: "error",
                    message: "An error occurred while triggering deployment.",
                });
                setDeploying(false);
            });
    };


    return (
        <Stack>
            <Paper shadow="md" radius="lg" p="lg" withBorder>
                <Title order={3} mb={20}>Manual Deploy</Title>
                <Button color="yellow" onClick={triggerDeploy} disabled={deploying}>
                    {deploying ? 'Deploying...' : 'Deploy Frontend'}
                </Button>
            </Paper>

            <Paper shadow="md" radius="lg" p="lg" withBorder>
                <Group mb={20}>
                    <Title order={3}>Automatic Deploy</Title>
                    <Button variant="light" compact size="xs" onClick={() => alert('TODO> Currently manual only')}>
                        Manage
                    </Button>
                </Group>
                <Text>Last deploy:</Text>
                <Text>Next deploy:</Text>
            </Paper>
        </Stack>
    )
}
