import { ColorScheme, ColorSchemeProvider, Global, MantineProvider } from "@mantine/core";
import { useLocalStorage } from "@mantine/hooks";
import { NotificationsProvider } from "@mantine/notifications";
import { Authenticated, ErrorComponent, Refine } from "@refinedev/core";
import { notificationProvider, RefineThemes, ThemedLayoutV2, ThemedTitleV2 } from "@refinedev/mantine";
import routerBindings, { CatchAllNavigate, NavigateToResource, UnsavedChangesNotifier } from "@refinedev/react-router-v6";
import { DataProvider } from "@refinedev/strapi-v4";
//import { useTranslation } from "react-i18next";
import { BrowserRouter, Outlet, Route, Routes } from "react-router-dom";
import { authProvider, axiosInstance } from "./authProvider";
import { API_URL } from "./constants";
//import React from "react";
import { AppIcon } from "components/layout/appIcon";
import { Dashboard } from "pages/dashboard";
import { AuthPage } from "components/pages/auth";
import { TalentList, TalentShow, TalentEdit, TalentCreate } from "pages/talents";
import { IconAffiliate, IconBrowserPlus, IconCoins, IconLayoutDashboard, IconListDetails, IconUserCircle } from "@tabler/icons";
import { Organize } from "pages/organize";
import { Deploy } from "pages/deploy";
import { OrderList } from "pages/orders";
import { Payments } from "pages/payments";

function App() {
  const [colorScheme, setColorScheme] = useLocalStorage<ColorScheme>({
    key: "mantine-color-scheme",
    defaultValue: "dark",
    getInitialValueInEffect: true,
  });
  //const { t, i18n } = useTranslation();

  const toggleColorScheme = (value?: ColorScheme) =>
    setColorScheme(value || (colorScheme === "dark" ? "light" : "dark"));

  /*
  const i18nProvider = {
    translate: (key: string, params: object) => t(key, params),
    changeLocale: (lang: string) => i18n.changeLanguage(lang),
    getLocale: () => i18n.language,
  };
  */
  return (
    <BrowserRouter>
      <ColorSchemeProvider
        colorScheme={colorScheme}
        toggleColorScheme={toggleColorScheme}
      >
        <MantineProvider
          theme={{ ...RefineThemes.Orange, colorScheme: colorScheme }}
          withNormalizeCSS
          withGlobalStyles
        >
          <Global styles={{ body: { WebkitFontSmoothing: "auto" } }} />
          <NotificationsProvider position="top-right">
            <Refine
              authProvider={authProvider}
              dataProvider={DataProvider(API_URL + `/api`, axiosInstance)}
              notificationProvider={notificationProvider}
              routerProvider={routerBindings}
              //i18nProvider={i18nProvider}
              resources={[{
                name: "dashboard",
                list: "/dashboard",
                meta: {
                  label: "Manage",
                  icon: <IconLayoutDashboard />
                }
              }, {
                name: "talents",
                list: "/talents",
                create: "/talents/create",
                edit: "/talents/edit/:id",
                show: "/talents/show/:id",
                meta: {
                  icon: <IconUserCircle />
                }
              }, {
                name: "orders",
                list: "/orders",
                meta: {
                  icon: <IconListDetails />
                }
              }, {
                name: "payments",
                list: "/payments",
                meta: {
                  label: "Payments",
                  icon: <IconCoins />
                }
              }, {
                name: "organize",
                list: "/organize",
                meta: {
                  label: "Organize",
                  icon: <IconAffiliate />
                }
              }, {
                name: "deploy",
                list: "/deploy",
                meta: {
                  label: "Deploy",
                  icon: <IconBrowserPlus />
                }
              },]}
              options={{
                disableTelemetry: true,
                //syncWithLocation: true,
                warnWhenUnsavedChanges: true,
              }}
            >
              <Routes>
                <Route
                  element={
                    <Authenticated
                      fallback={<CatchAllNavigate to="/login" />}
                    >
                      <ThemedLayoutV2
                        Title={({ collapsed }) => (
                          <ThemedTitleV2
                            collapsed={collapsed}
                            text="BV TV Admin"
                            icon={<AppIcon />}
                            wrapperStyles={{ whiteSpace: 'nowrap' }}
                          />
                        )}
                      >
                        <Outlet />
                      </ThemedLayoutV2>
                    </Authenticated>
                  }
                >
                  <Route
                    index
                    element={<NavigateToResource resource="dashboard" />}
                  />
                  <Route path="/dashboard">
                    <Route index element={<Dashboard />} />
                  </Route>
                  <Route path="talents">
                    <Route
                      index
                      element={<TalentList />}
                    />
                    <Route
                      path="show/:id"
                      element={<TalentShow />}
                    />
                    <Route
                      path="edit/:id"
                      element={<TalentEdit />}
                    />
                    <Route
                      path="create"
                      element={<TalentCreate />}
                    />
                  </Route>
                  <Route path="orders">
                    <Route
                      index
                      element={<OrderList />}
                      //element={<MantineInferencer />}
                    />
                  </Route>
                  <Route path="/organize">
                    <Route index element={<Organize />} />
                  </Route>
                  <Route path="/deploy">
                    <Route index element={<Deploy />} />
                  </Route>
                  <Route path="/payments">
                    <Route index element={<Payments />} />
                  </Route>
                </Route>
                <Route
                  element={
                    <Authenticated fallback={<Outlet />}>
                      <NavigateToResource />
                    </Authenticated>
                  }
                >
                  <Route
                    path="/login"
                    element={
                      <AuthPage
                        type="login"
                      />
                    }
                  />
                </Route>
                <Route
                  element={
                    <Authenticated>
                      <ThemedLayoutV2
                        Title={({ collapsed }) => (
                          <ThemedTitleV2
                            collapsed={collapsed}
                            text="BV TV Admin"
                            icon={<AppIcon />}
                          />
                        )}
                      >
                        <Outlet />
                      </ThemedLayoutV2>
                    </Authenticated>
                  }
                >
                  <Route path="*" element={<ErrorComponent />} />
                </Route>
              </Routes>
              <UnsavedChangesNotifier />
            </Refine>
          </NotificationsProvider>
        </MantineProvider>
      </ColorSchemeProvider>
    </BrowserRouter >
  );
}

export default App;
