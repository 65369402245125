import { Stack, Title, Text, Flex, Box, Group, ActionIcon, Button } from "@mantine/core";
import { CrudFilters, CrudSorting, HttpError, IResourceComponentsProps, useTable } from "@refinedev/core";
import { IconRefresh } from "@tabler/icons";
import { VideoPlayer } from "components/video/videoPlayer";
import { FRONTEND_URL } from "../../constants";
import { IOrder } from "interfaces";
import { MRT_ColumnDef, MRT_ColumnFiltersState, MRT_SortingState, MantineReactTable } from "mantine-react-table";
import { useEffect, useMemo, useState } from "react";

export const OrderList: React.FC<IResourceComponentsProps> = () => {
    const { tableQueryResult, setCurrent, setPageSize, pageCount, setFilters, setSorters } = useTable<IOrder, HttpError>({
        resource: "orders",
        metaData: {
            populate: ['talent', 'occassion']
        },
        pagination: {
            pageSize: 10,
        },
        filters: {
            defaultBehavior: "replace",
        },
    })

    const orders = tableQueryResult?.data?.data ?? []
    const columns = useMemo<MRT_ColumnDef<IOrder>[]>(
        () => [
            {
                accessorKey: 'talent.name',
                header: 'Talent',
                id: 'talent.name',
                enableColumnFilterModes: false,
                columnFilterModeOptions: ['contains']
            },
            {
                accessorFn: (row) => {
                    //const sDay = new Date(row.createdAt);
                    //sDay.setHours(0, 0, 0, 0); // remove time from date (useful if filter by equals exact date)
                    return new Date(row.dueDate);
                },
                //filterVariant: 'date-range',
                sortingFn: 'datetime',
                id: 'dueDate',
                header: 'Due date',
                enableColumnFilterModes: false,
                enableColumnFilter: false,
                Cell: ({ cell }) => cell.getValue<Date>()?.toLocaleDateString(),
                size: 70,
            },
            {
                accessorFn: (row) => {
                    //const sDay = new Date(row.createdAt);
                    //sDay.setHours(0, 0, 0, 0); // remove time from date (useful if filter by equals exact date)
                    return new Date(row.createdAt);
                },
                //filterVariant: 'date-range',
                sortingFn: 'datetime',
                id: 'createdAt',
                header: 'Date',
                enableColumnFilterModes: false,
                enableColumnFilter: false,
                Cell: ({ cell }) => cell.getValue<Date>()?.toLocaleDateString(),
                size: 70,
            },
            {
                accessorKey: 'status',
                id: 'status',
                header: 'Status',
                enableColumnFilterModes: false,
                filterVariant: 'select',
                mantineFilterSelectProps: {
                    data: ['open', 'paymentError', 'new', 'inprogress', 'complete'] as any
                },
                size: 80,
            },
            {
                accessorKey: 'orderID',
                header: 'OrderID',
                id: 'orderID',
                enableColumnFilterModes: false,
            },

        ], []
    )
    const [pagination, setPagination] = useState({
        pageIndex: 0,
        pageSize: 10,
    });
    useEffect(() => {
        setCurrent(pagination.pageIndex + 1);
        setPageSize(pagination.pageSize);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pagination.pageIndex, pagination.pageSize,]);

    const [sorting, setSorting] = useState<MRT_SortingState>([]);
    useEffect(() => {
        setSorters([{field: "createdAt", order: "desc"}])
        setSorting([{id: "createdAt", desc: true}])
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[])

    useEffect(() => {
        const crudSorting: CrudSorting = sorting.map((sorter) => ({
            field: sorter.id,
            order: sorter.desc ? "desc" : "asc",
        }));

        setSorters(crudSorting)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [sorting])

    const [columnFilters, setColumnFilters] = useState<MRT_ColumnFiltersState>([]);
    useEffect(() => {
        const crudFilters: CrudFilters = columnFilters.map((filter) => ({
            field: filter.id,
            value: filter.value,
            operator: filter.id === 'status' ? 'eq' : 'contains',
            condition: 'and',
        }));
        setFilters(crudFilters);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [columnFilters]);

    return (
        <Stack>
            <MantineReactTable
                columns={columns}
                data={orders ?? []}
                state={{ isLoading: tableQueryResult?.isLoading, pagination: pagination, sorting: sorting }}
                //enableStickyHeader
                manualSorting
                onSortingChange={setSorting}
                manualFiltering
                enableGlobalFilter={false}
                onColumnFiltersChange={setColumnFilters}
                initialState={{
                    showColumnFilters: true,
                    density: 'xs'
                }}
                mantineToolbarAlertBannerProps={tableQueryResult?.isError ? { color: 'red', children: 'Error loading data', } : undefined}
                manualPagination={true}
                pageCount={pageCount}
                onPaginationChange={setPagination}
                rowCount={tableQueryResult.data?.total}
                mantinePaginationProps={{
                    rowsPerPageOptions: ['10', '20', '50', '100']
                }}
                renderTopToolbarCustomActions={() => {
                    return (
                        <Flex align="baseline" gap="md">
                            <Title order={3} >Orders</Title>
                            <Text size="sm">{tableQueryResult.data?.total} entries found</Text>
                            <ActionIcon variant="transparent" onClick={() => tableQueryResult.refetch()}><IconRefresh size={18}/></ActionIcon>
                        </Flex>
                    )
                }}
                renderDetailPanel={({ row }) => {
                    const order = row.original
                    return (
                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: 'flex-start',
                            alignItems: 'center',
                            gap: '16px',
                            padding: '16px',
                        }}
                    >
                        <VideoPlayer videoURL={order.videoURL} height={350} />
                        <Stack>
                            <Text><b>Occasion:</b> {order.occasion ?? "n/a"}</Text>
                            <Group>
                                <Text><b>To:</b> {order.to ?? "n/a"}</Text>    
                                <Text><b>From:</b> {order.from ?? "n/a"} ({order.userEmail ?? "email n/a"})</Text>
                            </Group>
                            <Text><b>Request:</b> {order.requestDescription ?? "n/a"}</Text>
                            <Group>
                                <Text><b>Payment Status:</b> {order.paymentStatus ?? "n/a"}</Text>
                                <Text><b>Payed amount:</b> {order.payedAmount ?? "n/a"}</Text>
                            </Group>
                            <Button w={100} size="sm" variant="outline" compact onClick={() => window.open(`${FRONTEND_URL}/order/${order.orderID}`, '_blank')}>Show on BV TV</Button>
                        </Stack>
                    </Box>
                )}}
            />
        </Stack>
    )
};
