import { HttpError, IResourceComponentsProps, useBack, useList } from "@refinedev/core";
import { Edit, useForm } from "@refinedev/mantine";
import { NumberInput, TextInput, Checkbox, Select, MultiSelect, ActionIcon, Group, Avatar, Stack, Flex, Textarea, Title, Text, Button } from "@mantine/core";
import { ISubcategory, ITag, ITalent } from "interfaces";
import { IconArrowLeft } from "@tabler/icons";
import React from "react";
import { VideoPlayer } from "components/video/videoPlayer";
import { FRONTEND_URL } from "../../constants";

export const TalentEdit: React.FC<IResourceComponentsProps> = () => {
    const {
        getInputProps,
        saveButtonProps,
        refineCore: { queryResult },
    } = useForm<ITalent>({
        initialValues: {
            name: "",
            slug: "",
            description: "",
            price: "",
            active: "",
            fastDelivery: "",
            enrollAccepted: "",
            activateRequestAccepted: "",
            deliveryDays: "",
            fastDeliveryDays: "",
            subcategory: {
                id: null,
            },
            tags: []
        },
        refineCoreProps: {
            redirect: false,
            mutationMode: "pessimistic", // "pessimistic" | "optimistic" | "undoable",
            metaData: {
                populate: {
                    subcategory: {
                        populate: '*',
                        category: {
                            populate: '*',
                        },
                    },
                    image: {
                        populate: '*',
                    },
                    tags: {
                        populate: '*',
                    },
                    users_permissions_user: {
                        populate: {
                            email: true
                        },
                    }
                }
            },
        },
        validate: {
            slug: (value: any) => (!value ? "required" : value?.length < 3 ? "Too short title" : null),
            subcategory: {
                id: (value: any) =>
                    value?.length <= 0 || !value
                        ? "Subcategory is required"
                        : null,
            },
            price: (value: any) => (!value ? "required" : (value > 500 || value < 5) ? 'must be between 5 and 500' : null),
            name: (value: any) => (!value ? "required" : value.length < 3 ? 'must have at least 3 letters' : null),
            description: (value: any) => (!value ? "required" : value.length < 5 ? 'must have at least 5 letters' : null),
            deliveryDays: (value: any) => (!value ? "required" : (value > 14 || value < 3) ? 'must be between 3 and 14' : null),
            fastDeliveryDays: (value: any) => (!value ? "required" : (value > 3 || value < 1) ? 'must be between 1 and 3' : null),
        },
    });

    const talentsData = queryResult?.data?.data;

    const { data: subcategoryData } = useList<ISubcategory, HttpError>({
        resource: "subcategories",
        metaData: {
            populate: ["category"]
        }
    });
    const subcategorySelectData = subcategoryData?.data.map(item => ({ label: item.name, value: String(item.id), group: item.category.name })) ?? []
    const subcategoryValue = String(getInputProps("subcategory").value?.id) || null;
    const handleSubcategoryChange = (selectedSubcategory: any) => {
        getInputProps("subcategory").onChange({id: selectedSubcategory})
    }
    
    const { data: tagsData } = useList<ITag, HttpError>({
        resource: "tags",
    });
    const tagsSelectData = tagsData?.data.map(item => ({ label: item.name, value: String(item.id) })) ?? []
    const tagsValue = getInputProps("tags").value || [];
    const mappedTagsValue = tagsValue.map((item: { id: any; }) => {
        return (String(item.id));
    });
    const handleTagsChange = (selectedTags: any[]) => {
        const mappedSelectedTags = selectedTags.map(item => ({ id: item }));
        getInputProps("tags").onChange(mappedSelectedTags);
    };

    const back = useBack();

    return (
        <Edit
            saveButtonProps={{size: "lg", ...saveButtonProps }}
            goBack={<ActionIcon variant="light" size="md" onClick={() => back()}><IconArrowLeft /></ActionIcon>}
            headerButtons={[<Button size="sm" variant="light" compact disabled={getInputProps("subcategory").value?.id == null} onClick={() => window.open(`${FRONTEND_URL}/${talentsData?.slug}`, '_blank')}>Show on BVTV</Button>]}
            breadcrumb={<React.Fragment />}
            wrapperProps={{
                style: {
                    width: 800,
                },
                children: <React.Fragment />
            }}
            title={<Group>
                <Title order={2}>{talentsData?.name ?? "Edit Talent"}</Title>
                <Text size="xs">{talentsData?.socialPlatform}: {talentsData?.socialLinks}</Text>
                <Text size="xs">Mobile: {talentsData?.mobileNumber}</Text>
                <Text size="xs">Email: {talentsData?.users_permissions_user?.email ?? "n/a"}</Text>
            </Group>}
        >
            <Flex gap="xl">
                <Stack w={200} align="center">
                    <Avatar
                        w={150 * 0.75}
                        h={150}
                        variant="filled"
                        src={talentsData?.image !== null ? talentsData?.image[0].url : ""}
                    />
                    <VideoPlayer videoURL={talentsData?.videoURL} height={350} />
                </Stack>
                <Stack w={600}>
                    <Group>
                        <Checkbox
                            mt="sm"
                            label="Activate Request Accepted"
                            {...getInputProps("activateRequestAccepted", { type: "checkbox" })}
                            disabled={getInputProps("subcategory").value?.id == null}
                            indeterminate={
                                getInputProps("activateRequestAccepted").value === null ||
                                getInputProps("activateRequestAccepted").value === undefined
                            }
                        />
                        <Checkbox
                            mt="sm"
                            label="Active"
                            {...getInputProps("active", { type: "checkbox" })}
                            disabled={getInputProps("subcategory").value?.id == null}
                        />
                        <Checkbox
                            mt="sm"
                            label="Enroll Accepted"
                            {...getInputProps("enrollAccepted", { type: "checkbox" })}
                        />
                    </Group>
                    <Group>
                        <Select
                            mt="sm"
                            label="Subcategory"
                            placeholder="Select subcategory"
                            //{...selectProps}
                            //{...getInputProps("subcategory.id")}
                            value={subcategoryValue}
                            data={subcategorySelectData}
                            onChange={handleSubcategoryChange}
                            defaultValue={String(talentsData?.subcategory?.id)}
                            withAsterisk
                        />
                        <MultiSelect
                            value={mappedTagsValue}
                            onChange={handleTagsChange}
                            data={tagsSelectData}
                            mt={8}
                            label="Tags"
                            placeholder="Select tags"
                        />
                    </Group>
                    <Group>
                        <TextInput mt="sm" label="Name" {...getInputProps("name")} withAsterisk />
                        <TextInput mt="sm" label="Slug" {...getInputProps("slug")} withAsterisk />
                    </Group>
                    <Textarea
                        withAsterisk
                        mt="sm"
                        label="Description"
                        {...getInputProps("description")}
                    />
                    <Group>
                        <NumberInput mt="sm" label="Price" {...getInputProps("price")} withAsterisk/>
                        <NumberInput mt="sm" label="Delivery Days" {...getInputProps("deliveryDays")} withAsterisk/>
                    </Group>
                    <Group>
                        <NumberInput mt="sm" label="Fast Delivery Days" {...getInputProps("fastDeliveryDays")} withAsterisk/>
                        <Checkbox mt="sm" label="Fast Delivery" {...getInputProps("fastDelivery", { type: "checkbox" })} />
                    </Group>
                </Stack>
            </Flex>
        </Edit>
    );
};
