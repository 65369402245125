import { Button, Flex, Paper, Stack, Title } from "@mantine/core";
import { IResourceComponentsProps } from "@refinedev/core";

export const Payments: React.FC<IResourceComponentsProps> = () => {
    return (
        <Stack>
            <Paper shadow="md" radius="lg" p="lg" withBorder>
                <Flex align="baseline" gap="md">
                    <Title order={3} mb={20}>Pending payments</Title>
                    <Button color="yellow">Pay for Talent</Button>
                </Flex>
            </Paper>
            <Paper shadow="md" radius="lg" p="lg" withBorder>
                <Flex align="baseline" gap="md">
                    <Title order={3} mb={20}>Completed payments</Title>
                </Flex>
            </Paper>
        </Stack>
    );
};
