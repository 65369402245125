import { HttpError, IResourceComponentsProps, useList } from "@refinedev/core";
import { ICategory, ISubcategory, ITag } from "interfaces";
import { FRONTEND_URL } from "../../constants";
import { Button, Group, Paper, Stack, Title, Text } from "@mantine/core";

export const Organize: React.FC<IResourceComponentsProps> = () => {
    const { data: categoriesData } = useList<ICategory, HttpError>({
        resource: "categories",
        /*metaData: {
            populate: {
                subcategory: {
                  populate: '*',
                  category: {
                    populate: '*',
                  },
                },
            }
        },*/
    });
    const { data: subCategoriesData } = useList<ISubcategory, HttpError>({
        resource: "subcategories",
        metaData: {
            populate: {
                category: {
                    populate: '*',
                },
            }
        }
    });
    const { data: tagsData } = useList<ITag, HttpError>({
        resource: "tags",
    });


    const categories = categoriesData?.data ?? []
    const subCategories = subCategoriesData?.data ?? []
    const tags = tagsData?.data ?? []

    return (
        <Stack>
            <Paper shadow="md" radius="lg" p="lg" withBorder>
                <Group mb={20}>
                    <Title order={3}>Categories</Title>
                    <Button variant="light" compact size="xs" onClick={() => alert('TODO> Currently in backend')}>
                        Manage
                    </Button>
                </Group>
                <Group>
                    {categories.map((category: ICategory) => (
                        <Button px={20} variant="outline" compact onClick={() => window.open(`${FRONTEND_URL}/browse/${category.slug}`, '_blank')}>
                            {category.name}
                        </Button>
                    ))}
                </Group>
            </Paper>
            <Paper shadow="md" radius="lg" p="lg" withBorder>
                <Group mb={20}>
                    <Title order={3}>Subcategories</Title>
                    <Button variant="light" compact size="xs" onClick={() => alert('TODO> Currently in backend')}>
                        Manage
                    </Button>
                </Group>
                <Group>
                    {subCategories.map((subcategory: ISubcategory) => (
                        <Stack spacing={0} align="center" >
                            <Button px={20} variant="outline" compact onClick={() => window.open(`${FRONTEND_URL}/browse/${subcategory.category.slug}/${subcategory.slug}`, '_blank')}>
                                {subcategory.name}
                            </Button>
                            <Text size="xs">{subcategory.category.name}</Text>
                        </Stack>
                    ))}
                </Group>
            </Paper>
            <Paper shadow="md" radius="lg" p="lg" withBorder>
                <Group mb={20}>
                    <Title order={3}>Tags</Title>
                    <Button variant="light" compact size="xs" onClick={() => alert('TODO> Currently in backend')}>
                        Manage
                    </Button>
                </Group>
                <Group>
                    {tags.map((tag: ITag) => (
                        <Button px={20} variant="outline" compact onClick={() => window.open(`${FRONTEND_URL}/browse/${tag.slug}`, '_blank')}>
                            {tag.name}
                        </Button>
                    ))}
                </Group>
            </Paper>

        </Stack>
    );
};
