import React from "react";
import { useMantineTheme } from '@mantine/core';
import { Loader } from '@mantine/core';

export const FullScreenLoader: React.FC = () => {
  const theme = useMantineTheme();
  const background = theme.colorScheme === 'dark' ? theme.colors.dark[7] : theme.colors.gray[1];

  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100vh',
        background: background,
      }}
    >
      <Loader size={64} color={theme.colorScheme === 'dark' ? 'gray' : 'blue'} />
    </div>
  );
}